body {
  background-color: #161616;
}
.list-inline-item {
    margin: 32px;
}

@media (min-width: 992px) {
    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    h1 {
        font-size: calc(7vw) !important;
        text-align: center;
    }
    h2 {
        font-size: calc(5vw) !important;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 2vw !important;
    }

    .subheading {
        font-size: 3vw !important;
    }

    .imgExp {
        width: 15vw;
        height: 15vw;
    }

    .imgAward {
        width: 6vw;
        height: 6vw;
    }
}

@media (max-width: 992px) {
    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    h1 {
        font-size: 18vw !important;
    }
    h2 {
        font-size: 15vw !important;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 4vw !important;
    }

    .subheading {
        font-size: 4vw !important;
    }

    .imgExp {
        width: 25vw;
        height: 25vw;
    }

    .imgAward {
        width: 20vw;
        height: 20vw;
    }
}

.fa-ul {
margin-left: 0px !important;
}

/* Begin Timeline */
.timeline {
  padding: 100px 40px;
  position: relative;
}
.timeline:before {
  content: '';
  position: absolute;
  top: 0px;
  left: calc(33% + 15px);
  bottom: 0px;
  width: 4px;
  background: #ddd;
}
.timeline:after {
  content: "";
  display: table;
  clear: both;
}
.entry {
  clear: both;
  text-align: left;
  position: relative;
}
.entry .title {
  margin-bottom: .5em;
  float: left;
  width: 33%;
  padding-right: 30px;
  text-align: right;
  position: relative;
}
.entry .title:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border: 4px solid red;
  background-color: #fff;
  border-radius: 100%;
  top: 15%;
  right: -8px;
  z-index: 99;
}
.entry .title h3 {
  margin: 0;
  font-size: 120%;
}
.entry .title p {
  margin: 0;
  font-size: 100%;
}
.entry .body {
  margin: 0 0 3em;
  float: right;
  width: 66%;
  padding-left: 30px;
}
.entry .body p {
  line-height: 1.4em;
}
.entry .body p:first-child {
  margin-top: 0;
  font-weight: 400;
}
.entry .body ul {
  color: rgb(255, 255, 255);
  padding-left: 0;
  list-style-type: none;
}
.entry .body ul li:before {
  content: "–";
  margin-right: .5em;
}

/* End Timeline */

#about .resume-section-content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  text-align: justify;
}